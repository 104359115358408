import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'id',
    'presentButton',
    'absentButton',
    'presence',
    'name',
    'loc',
    'updatedBy',
    'updatedAt',
    'loggedLabel',
    'comment',
  ]

  connect () {
    this.updatePresence(this.presence)
    this.updateLoggedBy(this.updatedAt, this.updatedBy)
  }

  parsedValue (value) {
    if (value === 'null' || value === 'undefined') {
      return null
    }
    return value
  }

  get presence () {
    return this.parsedValue(this.data.get('presence'))
  }

  set presence (value) {
    this.data.set('presence', value)
  }

  get updatedBy () {
    return this.parsedValue(this.data.get('updatedBy'))
  }

  set updatedBy (value) {
    this.data.set('updatedBy', value)
  }

  get updatedAt () {
    return this.parsedValue(this.data.get('updatedAt'))
  }

  set updatedAt (value) {
    this.data.set('updatedAt', value)
  }

  updateLoggedBy (updatedAt = '', updatedBy = '', tz = null, rowIndex = null) {
    this.updatedBy = updatedBy
    this.updatedAt = updatedAt
    if (!this.hasLoggedLabelTarget) return

    if (!this.updatedBy || !this.updatedAt) {
      this.loggedLabelTarget.innerHTML = ''
      this.loggedLabelTarget.parentElement?.classList?.add?.('tw-hidden')
      return
    }
    const date = new Date(this.updatedAt)
    let formattedDate = ''
    if (tz == null) {
      formattedDate = date.toLocaleString()
    } else {
      formattedDate = date.toLocaleString('en-US', { timeZone: tz })
    }
    this.loggedLabelTarget.innerHTML = `${this.updatedBy} at ${formattedDate}`
    this.loggedLabelTarget.parentElement?.classList?.remove?.('tw-hidden')
    if (rowIndex !== null && window.jQuery) {
      const columnCount = window.jQuery.fn.dataTable.tables({ api: true }).columns()[0].length - 1
      const hiddenUpdatedBy = columnCount - 2
      const hiddenUpdatedAt = columnCount - 1
      window.jQuery.fn.dataTable.tables({ api: true }).cell({ row: rowIndex, column: hiddenUpdatedBy }).data(this.updatedBy)
      window.jQuery.fn.dataTable.tables({ api: true }).cell({ row: rowIndex, column: hiddenUpdatedAt }).data(formattedDate)
    }
  }

  togglePresence (event) {
    const id = this.data.get('id')
    const dtRowIndex = window.jQuery?.(`[data-attendance-card-id= ${id} ]`)?.[0]?._DT_RowIndex
    let newPresence
    if (event.currentTarget.value === 'present') {
      newPresence = this.presence === 'present' ? 'unrecorded' : 'present'
    } else {
      newPresence = this.presence === 'absent' ? 'unrecorded' : 'absent'
    }
    const flash = document.getElementById('flash-message')
    const that = this
    flash.innerHTML = ''
    const options = {
      method: 'PUT',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ patient_attendance: { presence: newPresence } }),
    }
    document.getElementById('ajax_working').style.display = 'block'
    fetch('/attendances/' + id, options)
      .then(function (response) {
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          return response.json()
        }
      })
      .then(function (json) {
        that.updatePresence(json.presence, dtRowIndex)
        that.updateLoggedBy(json.updated_at, json.updated_by, window.Kipu?.globals?.timeZoneLong, dtRowIndex)
      })
      .catch(function (error) {
        flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>' + error + '</p></div>'
      })
      .finally(() => {
        document.getElementById('ajax_working').style.display = 'none'
        this.dispatchUpdate()
      })
  }

  openModal () {
    const id = this.data.get('id')
    document.getElementById('ajax_working').style.display = 'block'
    fetch(`/attendances/${id}/modal`)
      .then(function (response) {
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          return response.text()
        }
      })
      .then(function (html) {
        document.getElementById('custom-order-dialog').innerHTML = html
        if (window.jQuery) {
          window.jQuery('#custom-order-dialog').dialog({
            title: 'Edit Attendance',
            modal: true,
            resizable: true,
            width: '60%',
          })
        }
      })
      .catch(function (error) {
        document.getElementById('flash-message').innerHTML = error
      })
      .finally(function () {
        document.getElementById('ajax_working').style.display = 'none'
      })
  }

  deleteAttendance () {
    const id = this.data.get('id')
    const that = this
    const options = {
      method: 'delete',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      },
    }
    document.getElementById('ajax_working').style.display = 'block'
    fetch(`/attendances/${id}`, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          that.element.remove()
        }
      })
      .catch((error) => {
        document.getElementById('flash-message').innerHTML = error
      })
      .finally(() => {
        document.getElementById('ajax_working').style.display = 'none'
        this.dispatchUpdate()
      })
  }

  dispatchUpdate () {
    Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
      element.dispatchEvent(new Event('attendanceTableUpdated'))
    })
  }

  updatePresence (value, rowIndex = null) {
    this.presence = value
    if (this.hasPresentButtonTarget) {
      if (value === 'present') {
        this.presentButtonTarget.classList.add('present-status')
      } else {
        this.presentButtonTarget.classList.remove('present-status')
      }
    }

    if (this.hasAbsentButtonTarget) {
      if (value === 'absent') {
        this.absentButtonTarget.classList.add('absent-status')
      } else {
        this.absentButtonTarget.classList.remove('absent-status')
      }
    }

    if (rowIndex !== null && window.jQuery) {
      const columnCount = window.jQuery.fn.dataTable.tables({ api: true }).columns()[0].length - 1
      window.jQuery.fn.dataTable.tables({ api: true }).cell({ row: rowIndex, column: columnCount - 4 }).data(value.substr(0, 1).toUpperCase() + value.substr(1))
    }
  }

  updateComment (value, rowIndex = null) {
    this.comment = value
    if (rowIndex !== null && window.jQuery) {
      const columnCount = window.jQuery.fn.dataTable.tables({ api: true }).columns()[0].length - 1
      window.jQuery.fn.dataTable.tables({ api: true }).cell({ row: rowIndex, column: columnCount - 5 }).data(this.comment)
    }
  }

  updateAbsenceReason (value, rowIndex = null) {
    this.absence_reason = value
    if (rowIndex !== null && window.jQuery) {
      const columnCount = window.jQuery.fn.dataTable.tables({ api: true }).columns()[0].length - 1
      window.jQuery.fn.dataTable.tables({ api: true }).cell({ row: rowIndex, column: columnCount - 3 }).data(this.absence_reason)
    }
  }
}
