import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'absenceReason',
    'comment',
    'presence',
  ]

  get detail() {
    return {
      presence: this.presenceTarget.value,
      absenceReason: this.absenceReasonTarget.value,
      comment: this.commentTarget.value
    }
  }

  connect() {
    logger.debug('EditAttendancesModalController connected', this)
    this.setAbsenceReasonVisibility()
  }

  setAbsenceReasonVisibility() {
    if (this.presenceTarget.value !== 'absent') {
      this.absenceReasonTarget[this.absenceReasonTarget.selectedIndex].removeAttribute('selected')
      this.absenceReasonTarget[0].setAttribute('selected', 'selected')
      this.absenceReasonTarget.value = ''
      this.absenceReasonTarget.disabled = true;
    } else {
      this.absenceReasonTarget.disabled = false
    }
  }

  editPatient(){
    const id = this.data.get("id");
    const { presence, absenceReason, comment } = this.detail
    const options = {
      method: 'PUT',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        patient_attendance: {
          presence,
          absence_reason: absenceReason,
          comment
        }
      })
    }

    document.getElementById('ajax_working').style.display = "block";

    fetch("/attendances/" + id, options)
        .then(function(response){
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            return response.json();
          }
        })
        .then((json) => {
          let updatedRow = $('[data-attendance-card-id="' + json.id + '"]')[0]
          let rowCon = this.application.getControllerForElementAndIdentifier(updatedRow,'attendance-card');
          let dtRowIndex = updatedRow._DT_RowIndex;
          let hiddenAbsenceReason = -4;
          rowCon.updatePresence(json.presence, dtRowIndex)
          $.fn.dataTable.tables({api: true}).cell(dtRowIndex, hiddenAbsenceReason).data(json.absence_reason)
          $.fn.dataTable.tables({api: true}).cell(`#attendance-comment-${json.id}`).data(json.comment).draw()
          rowCon.updateLoggedBy(json.updated_at, json.updated_by, timeZoneLong, dtRowIndex)
        })
        .catch(function(error){
          // Add error handling
        })
        .finally(function() {
          $("#custom-order-dialog").dialog("destroy")
          document.getElementById('ajax_working').style.display = "none"
        })
  }

  bulkUpdate(ev) {
    ev?.preventDefault?.()

    logger.debug(ev?.currentTarget?.tagName)

    const { presence, absenceReason, comment } = this.detail
    const markAll = this.data.get('markAll') === 'true'
    const path = markAll
      ? `/attendances/mark_all_${presence}`
      : '/attendances/mark_selected'
    const url = new URL(path, window.location.origin)
    const ids = []

    if (!markAll) {
      const rows = Array.from(document.querySelectorAll('.attendance-card')).filter(function(el) {
        return window.getComputedStyle(el).getPropertyValue("display") !== 'none'
          && el.querySelector('[name=patient_ids\\[\\]]')?.checked
      })

      if (rows.length === 0) {
        alert('Please select at least one record to update')
        return
      }

      ids.push(...rows.map((el) => el.dataset.attendanceCardId))
    }

    if (presence === 'absent' && !absenceReason) {
      if (!window.confirm('You have not selected an absence reason. Are you sure you want to continue?')) {
        return
      }
    }

    if (window.confirm(`Are you sure you'd like to mark all items as ${presence}? This change will modify all ${markAll ? 'attendances matching the current filters' : 'selected records'} and will overwrite any current documentation.`)) {
      $("#custom-order-dialog").dialog('close')

      const params = {
        absence_reason: absenceReason,
        presence,
        comment,
        ids,
      }
      const flash = document.getElementById("flash-message")

      if (markAll) {
        delete params.ids

        params.mark_all = true
      }

      if (presence === 'absent') {
        if (!params.absence_reason && !window.confirm('You have not selected an absence reason. Are you sure you want to continue?')) {
          return
        }
      } else {
        delete params.absence_reason
      }

      new URLSearchParams(window.location.search).forEach((value, key) => {
        url.searchParams.append(key, value)
      })

      const options = {
        method: "PATCH",
        credentials: "include",
        mode: "same-origin",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      }

      document.getElementById("ajax_working").style.display = "block";
      flash.innerHTML = ''

      fetch(url, options)
        .then(function(response){
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            return response.json();
          }
        })
        .then(() => {
          window.dispatchEvent(new CustomEvent('reloadAttendancesTable'))
        })
        .catch((error) => {
          flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>'+ error + '</p></div>';
        })
    }
  }
}
