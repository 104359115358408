import { Controller } from 'stimulus'
import { usePostMessage } from '~shared/PostMessage'

const post = usePostMessage()

// TODO: Get Rid of the Rest of this class.
// it just makes it more complex to manage state in two controllers
// instead of properly using targets and connect callbacks
export default class extends Controller {

  static targets = [ "query",  "createRosterButton", "hideTableButton", 'settings', 'expandTableBtn']
  static values = { action: String }
  // Storing settings in local settings
  get settings() {
    const settings = {
      showSummary: true,
      expandedTable: true,
    }

    const existing = JSON.parse(localStorage.getItem('attendance-settings') || '{}')

    Object.assign(settings, existing)

    if ('show_summary' in settings) {
      const { show_summary, expanded_table } = settings
      delete settings.show_summary
      delete settings.expanded_table

      settings.showSummary = show_summary
      settings.expandedTable = expanded_table

      localStorage.setItem('attendance-settings', JSON.stringify(settings))
    }

    return Object.assign(settings, existing)
  }

  initialize(){
    $('.past_datepicker').datepicker({
      maxDate: 0,
      dateFormat: window.datepickerDateFormat,
    });

    // Submit on datepick
    $('#date').bind('change', function () {
      $(this).closest('form').submit();
    });

    $('#attendance-datepicker').bind('click', function () {
      if($('.past_datepicker').datepicker('widget').is(":visible")){
        $('.past_datepicker').removeClass('hidden').datepicker('hide').addClass('hidden');
      }else {
        $('.past_datepicker').removeClass('hidden').datepicker('show').addClass('hidden');
      }
    });
  }

  connect(){
    this.onUpdate()
  }

  setSettings(setting, value) {
    const settings = this.settings
    const valueWas = settings[setting]
    settings[setting] = value
    localStorage.setItem('attendance-settings', JSON.stringify(settings))
    post('kipu_attendance_settings', { settings: Object.assign({}, settings), changed: setting, value, valueWas })

    return Object.assign({}, settings)
  }

  onUpdate() {
    this.setSummaryVisibility()
    this.setCompactAttTable()
  }

  toggleSummaryVisibility() {
    const { showSummary } = this.settings
    this.setSettings('showSummary', !showSummary)

    this.setSummaryVisibility()
  }

  toggleCompactAttTable() {
    this.setSettings('expandedTable', !this.settings.expandedTable)

    this.setCompactAttTable()
  }

  setCompactAttTable() {
    if (this.settings.expandedTable === false) {
      $('.attendance-image').hide();
      $('.attendance-timestamp').hide();
      $('#photo-column-th').hide()
      if (this.hasExpandTableBtnTarget) {
        this.expandTableBtnTarget.innerText = 'Show Expanded Table';
      }
    } else {
      $('.attendance-image').show();
      $('.attendance-timestamp').show();
      $('#photo-column-th').show();
      if (this.hasExpandTableBtnTarget) {
        this.expandTableBtnTarget.innerText = 'Show Compact Table';
      }
    }
  }

  setSummaryVisibility() {
    let table = document.getElementById('attendance-loc-table')
    if (this.settings.showSummary === false) {
      table?.classList?.add('tw-hidden')
      if (this.hasHideTableButtonTarget) {
        this.hideTableButtonTarget.innerText = 'Show Summary'
      }
    } else {
      table?.classList?.remove('tw-hidden')
      if (this.hasHideTableButtonTarget) {
        this.hideTableButtonTarget.innerText = 'Hide Summary'
      }
    }
    table.display = ''
  }

  setButtonVisibility(e) {
    const visible = [].slice.call(document.getElementsByClassName('attendance-card')).filter(function(el) {
      return window.getComputedStyle(el).getPropertyValue('display') !== 'none'
    })

    const target = e.currentTarget
    target.classList.remove('hide')
    target.style.display = ''

    if (!visible.length && target.dataset.showWhenEmpty === 'false') {
      target.classList.add('tw-hidden')
    } else if (visible.length > 0 && target.dataset.showWhenEmpty === 'true') {
      target.classList.add('tw-hidden')
    } else {
      target.classList.add('tw-hidden')
    }
  }

  changeRosterButtonText() {
    let coll = document.getElementsByClassName('attendance-card')
    if (coll.length > 0) {
      this.createRosterButtonTarget.innerText = 'Update Roster'
    } else {
      this.createRosterButtonTarget.innerText = 'Create Roster'
    }
  }

  addPatientModal() {
    var attendance_date = document.getElementById('attendance-datepicker').getAttribute('data-attendance-date');
    var options = {
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    };
    document.getElementById("ajax_working").style.display = "block";
    fetch(`/attendances/patient_attendance_modal?attendance_date=${attendance_date}`, options)
        .then(function(response){
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            return response.text()
          }
        })
        .then(function(html){
          document.getElementById("custom-order-dialog").innerHTML = html;
          $("#custom-order-dialog").dialog({
            title: 'Add Patient',
            modal: true,
            resizable: true,
            width: 600,
            height: 400,
            open: function() {
              $('#attendance-patient-ids').select2({
                placeholder: "Add missing patients.",
                dropdownParent: $('#custom-order-dialog'),
                closeOnSelect: false,
                allowClear: true
              }).focus();
              $('.select2-search__field').click();
            }
          });
          document.getElementById("ajax_working").style.display = "none"
        })
        .catch(function(error){
          document.getElementById("flash-message").innerHTML = error;
        })
        .finally(function() {
          document.getElementById("ajax_working").style.display = "none";
        })
  }

  addPatientToAttendance(){
    let p = document.getElementById('attendance-patient-ids');
    let patient_ids = Array.from(p.selectedOptions).map(opt=> opt.value);
    let date_string = document.getElementById('attendance-datepicker').getAttribute('data-attendance-date');
    let flash = document.getElementById("flash-message");
    let options = {
      method: 'POST',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({patient_ids: patient_ids, attendance_date: date_string})
    };
    document.getElementById("ajax_working").style.display = "block";
    fetch("/attendances/", options)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            this.triggerReload()
          }
        })
        .catch((error) => {
          flash.innerHTML = '<div class="notification-error mbottom1em"><h2>Errors Found</h2><p>'+ error + '</p></div>';
        })
        .finally(() => {
          document.getElementById("ajax_working").style.display = "none";
          $("#custom-order-dialog").dialog('destroy')
          this.dispatchUpdate()
        })
  }

  dispatchUpdate() {
    Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
      element.dispatchEvent(new Event('attendanceTableUpdated'))
    })
  }

  triggerReload() {
    window.dispatchEvent(new CustomEvent('reloadAttendancesTable'))
  }
}
