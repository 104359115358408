import { ref, shallowReactive } from 'vue'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

export const useToastStore = defineStore('KipuToastAlerts', () => {
  const stackSize = ref(3)
  const toasts = shallowReactive([])
  const addToast = (add) => {
    const toast = {
      ...add,
      id: uuid(),
    }
    toasts.splice(0, (toasts.length - 1) - stackSize.value, toast)
    if (toast.autoHideDuration) {
      setTimeout(() => {
        removeToast(toast.id)
      }, toast.autoHideDuration)
    }
  }
  const removeToast = (id) => {
    const foundIndex = toasts.findIndex((toast) => toast.id === id)
    if (foundIndex !== -1) {
      toasts.splice(foundIndex, 1)
    }
  }

  return { toasts, stackSize, addToast, removeToast }
})
