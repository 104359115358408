import { usePostMessage } from '@/composables/usePostMessageListener'

export const kisEngineOverrides = () => {
  window.KisEngine = window.KisEngine || {}

  /*
    Prevent KisEngine from injecting it's own
    casefileModalHandler function
  */
  window.KisEngine.casefileModalActivated = true

  if (window.KisEngine.pdfBuilderActivated) return

  window.KisEngine.pdfBuilderActivated = true

  const post = usePostMessage()

  document.addEventListener('click', (event) => {
    const target =
      event?.target?.closest?.('[data-casefile-trigger]') ||
      event?.target?.closest?.('[data-casefile-form]')

    if (target) {
      event.preventDefault()

      post('kipu_pdf_package', {
        action: 'open',
        config: Object.assign({}, target.dataset),
      })
    }
  })
}
