<script setup>
import { computed, defineProps } from 'vue'

import HeroIcon from '@/components/HeroIcon'

const props = defineProps({
  severity: {
    type: String,
    default: 'info',
  },
})

const severityEnum = {
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
}

const iconMap = {
  [severityEnum.INFO]: 'information-circle',
  [severityEnum.WARNING]: 'exclamation-triangle',
  [severityEnum.SUCCESS]: 'check-circle',
  [severityEnum.ERROR]: 'exclamation-circle',
}

const wrapperBaseClass = [
  'tw-flex',
  'tw-flex-start',
  'tw-items-center',
  'tw-rounded-md',
  'tw-p-3',
  'tw-mb-2',
]

const wrapperSeverityClass = {
  [severityEnum.INFO]: [
    'tw-bg-k-info-light',
  ],
  [severityEnum.WARNING]: [
    'tw-bg-k-warning-light',
  ],
  [severityEnum.SUCCESS]: [
    'tw-bg-k-success-light',
  ],
  [severityEnum.ERROR]: [
    'tw-bg-k-error-light',
  ],
}

const iconBaseClasses = [
  'tw-mr-2',
  'tw-w-6',
  'tw-h-6',
]
const iconSevrityClasses = {
  [severityEnum.INFO]: [
    'tw-text-k-info-dark',
  ],
  [severityEnum.WARNING]: [
    'tw-text-k-warning-dark',
  ],
  [severityEnum.SUCCESS]: [
    'tw-text-k-success-dark',
  ],
  [severityEnum.ERROR]: [
    'tw-text-k-error-dark',
  ],
}

const textClasses = [
  'tw-grow',
  'tw-text-base',
  'tw-text-black',
]

const wrapperClass = computed(() => [
  ...wrapperBaseClass,
  ...wrapperSeverityClass[props.severity],
])
const iconClasses = computed(() => [
  ...iconBaseClasses,
  ...iconSevrityClasses[props.severity],
])
const icon = computed(() => iconMap[props.severity])

</script>

<template>
  <div :class="wrapperClass">
    <HeroIcon
      :class="iconClasses"
      :icon="icon"
      outlined
    />
    <div :class="textClasses">
      <slot />
    </div>
  </div>
</template>
