/* explicitly import shared packages to guarantee definition for V1 */
import '~shared/Axios'
import '~shared/CSRFTokens'
import '~shared/Microtasks'
import '~shared/Ready'
import '~shared/Logging'
import '~shared/Websockets'

import { createApp, nextTick } from 'vue'
import { createPinia } from 'pinia'
import { useApplicationStack } from '@/lib/utils/StackingContext'
import { kisEngineOverrides } from '@/lib/gem-overrides/KisEngine'
// import { scaleKipuPage } from '@/lib/utils/PageScaling'

import App from './components/App'

kisEngineOverrides()

pageLoaded(useApplicationStack)

const app = createApp(App)
app.provide('appVersion', '0.2')
app.provide('enableTimout', !window.disableSessionTimeout)

const pinia = createPinia()

app.use(pinia)

pageLoaded(async () => {
  // scaleKipuPage()

  if (window.jQuery) {
    app.provide('jQuery', window.jQuery)
  }

  nextTick(() => document.getElementById('vue-3-app') && app.mount('#vue-3-app'))
})
