<script setup>
import { computed } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    required: true,
    default: '',
  },

  size: {
    type: [Number, String],
    validator (value) {
      if (String(Number(value)) !== String(value)) return false
      return [18, 24, 36, 48].indexOf(Number(value)) !== -1
    },
    default: 18,
  },

  rotate: {
    type: [Number, String],
    validator (value) {
      if (String(Number(value)) !== String(value)) return false
      return [45, 90, 135, 180, 225].indexOf(Number(value)) !== -1
    },
    default: 45,
  },

  flip: {
    type: String,
    validator (value) {
      // The value must match one of these strings
      return ['h', 'v'].indexOf(value) !== -1
    },
    default: 'h',
  },

  spin: {
    type: Boolean,
    default: false,
  },

  theme: {
    type: String,
    validator (value) {
      // The value must match one of these strings
      return ['light', 'dark', 'none'].indexOf(value) !== -1
    },
    default: 'light',
  },

  disabled: {
    type: Boolean,
    default: false,
  },
})

const iconClass = computed(() => (
  props.icon ? `mdi-${props.icon}` : ''
))

const classes = computed(() => ({
  mdi: true,
  [iconClass.value]: true,
  [`mdi-${props.size}px`]: props.size,
  [`mdi-rotate-${props.rotate}`]: props.rotate && !props.flip,
  [`mdi-flip-${props.flip}`]: props.flip && !props.rotate,
  'mdi-spin': props.spin,
  [`mdi-${props.theme}`]: !!props.theme && props.theme !== 'none',
  'mdi-inactive': props.theme && props.disabled,
}))

const emit = defineEmits(['clicked'])

function emitClicked () {
  emit('clicked')
}

</script>

<template>
  <i
    :class="classes"
    @click="emitClicked"
  />
</template>
