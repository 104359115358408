<script setup>
import { computed, defineProps } from 'vue'
import HeroIcon from '@/components/HeroIcon'

const props = defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'md',
  },
  icon: {
    type: String,
    default: undefined,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  transparent: {
    type: Boolean,
    default: false,
  },
})

const colorEnum = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  MONO: 'mono',
})
const sizeEnum = Object.freeze({
  SM: 'sm',
  MD: 'md',
})

const colorPallete = {
  [colorEnum.PRIMARY]: [
    'tw-text-k-true-blue-500',
    'hover:tw-bg-k-true-blue-100',
    'hover:tw-text-k-true-blue-300',
    'active:tw-bg-k-true-blue-50',
    'active:tw-text-k-true-blue-300',
  ],
  [colorEnum.SECONDARY]: [
    'tw-text-k-purple-500',
    'hover:tw-bg-k-purple-100',
    'hover:tw-text-k-purple-400',
    'active:tw-bg-k-purple-50',
    'active:tw-text-k-purple-500',
  ],
  [colorEnum.MONO]: [
    'tw-text-k-gray-700',
    'hover:tw-bg-k-gray-300',
    'hover:tw-text-k-gray-500',
    'active:tw-bg-k-gray-200',
    'active:tw-text-k-gray-700',
  ],
}

const baseStyles = [
  props.transparent ? 'tw-bg-transparent' : 'tw-bg-white',
  'tw-rounded-full',
  'tw-overflow-hidden',
  'tw-shadow-none',
  'tw-border-none',
  'tw-bg-none',
  'tw-m-0',
  'tw-p-0',
  'tw-cursor-pointer',
  'focus:tw-outline-k-purple',
  'disabled:tw-opacity-50',
  'disabled:tw-cursor-not-allowed',
  'disabled:tw-bg-white',
]

const sizeStyles = {
  [sizeEnum.MD]: [
    'tw-w-6',
    'tw-h-6',
  ],
  [sizeEnum.SM]: [
    'tw-w-4',
    'tw-h-4',
  ],
}

const iconWrapperStyles = [
  'tw-flex',
  'tw-p-2',
  'tw-items-center',
  'tw-justify-center',
]

const iconStyles = [
  ...sizeStyles[props.size],
  colorPallete[props.color][0],
]

const themeColorStyles = computed(() => [
  ...baseStyles,
  ...colorPallete[props.color],
])
</script>

<template>
  <button
    :class="themeColorStyles"
    type="button"
    data-skip-legacy="true"
  >
    <span :class="iconWrapperStyles">
      <slot>
        <HeroIcon
          :class="iconStyles"
          :icon="props.icon"
          :outline="props.outline"
        />
      </slot>
    </span>
  </button>
</template>
