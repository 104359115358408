<script setup>
import { defineProps, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useToastStore } from '../stores/ToastStore'
import AlertMessage from '@/components/alert/AlertMessage'
import IconButton from '@/components/kipu-ui/buttons/IconButton'

const toastStore = useToastStore()
const { toasts, stackSize: storeStackSize } = storeToRefs(toastStore)

const props = defineProps({
  vertical: {
    type: String,
    default: 'bottom',
    validation: (position) => ['bottom', 'top', 'center'].includes(position),
  },
  horizontal: {
    type: String,
    default: 'center',
    validation: (position) => ['left', 'right', 'center'].includes(position),
  },
  stackSize: {
    type: Number,
    default: 3,
  },
})

const baseAlertStyles = [
  'tw-min-w-60',
]

const verticalPositionStyles = {
  bottom: ['tw-bottom-0'],
  top: ['tw-top-0', 'tw-mt-2'],
  center: ['tw-top-1/2', 'tw--translate-y-1/2'],
}

const horizontalPositionStyles = {
  left: ['tw-left-0', 'tw-ml-2'],
  right: ['tw-right-0', 'tw-mr-2'],
  center: ['tw-left-1/2', 'tw--translate-x-1/2'],
}
const alertStyles = [
  ...baseAlertStyles,
  ...verticalPositionStyles[props.vertical],
  ...horizontalPositionStyles[props.horizontal],
]

onMounted(() => {
  storeStackSize.value = props.stackSize
})

</script>
<template>
  <div
    class="tw-flex tw-fixed tw-flex-col-reverse"
    :class="alertStyles"
  >
    <AlertMessage
      v-for="{ id, message, severity, hideClose, autoHideDuration } in toasts"
      :key="id"
      :severity="severity"
      class="tw-max-w-1/2 tw-min-w-80 tw-shadow-xl tw-animate-fade-in-up"
    >
      <div class="tw-flex tw-items-center tw-items-between">
        <span class="tw-grow">{{ message }}</span>
        <IconButton
          v-if="!hideClose || !autoHideDuration "
          class="tw-ml-4"
          icon="x-mark"
          color="mono"
          size="sm"
          transparent
          @click="toastStore.removeToast(id)"
        />
      </div>
    </AlertMessage>
  </div>
</template>
