import { fetch as fetchPolyfill } from 'whatwg-fetch'
import { getMetaContent, refreshCSRFTokens } from '@/lib/utils/CSRFTokens'
import { usePostMessage } from '@/composables/usePostMessageListener'

const notify = usePostMessage(window)

export class FetchError extends Error {
  constructor (message, { response, result }) {
    super(message)
    this.fetchResponse = response
    this.fetchResult = result
  }

  get fetchStack () {
    if (this.statusMessage) {
      return `URL: ${this.fetchUrl}\nStatus: ${this.statusMessage}\n${this.stack}`
    } else {
      return this.stack
    }
  }

  get statusMessage () {
    if (this._statusMessage) return this._statusMessage
    try {
      const statusText = this.fetchResponse?.statusText
      const statusCode = this.fetchResponse?.status
      if (!statusText || !statusCode) throw new Error(statusCode ? String(statusCode) : 'Unknown Status')
      this._statusMessage = `${statusCode} - ${statusText}`
    } catch (err) {
      this._statusMessage = 'Unknown Status'
    }
    return this._statusMessage
  }

  get fetchUrl () {
    return this.fetchResponse.url
  }

  get requestId () {
    return this.fetchResponse?.headers?.get?.('X-Request-ID')
  }

  get fetchResult () {
    return this._result
  }

  set fetchResult (value) {
    return (this._result = value)
  }

  get fetchResponse () {
    return this._response
  }

  set fetchResponse (value) {
    this._response = value.clone()
  }
}

export function customFetch (url, options) {
  const { headers: givenHeaders, navigate, router, html, json, multipart, ...rest } = (options || {})
  const headers = rest.credentials === 'omit'
    ? { ...(givenHeaders || {}) }
    : { 'X-CSRF-Token': getMetaContent('csrf-token'), ...(givenHeaders || {}) }

  if (html) {
    headers.Accept = 'text/html'
    headers['Content-Type'] ||= 'application/json'
  }

  if (json) {
    headers.Accept = 'application/json'
    headers['Content-Type'] ||= 'application/json'
  }

  if (multipart) delete headers['Content-Type']

  let controller

  if (!rest.signal) {
    controller = new AbortController()
    rest.signal = controller.signal
  }

  const request = fetchPolyfill(url, {
    headers,
    credentials: 'include',
    ...rest,
  })

  if (controller) request.controller = controller

  const promise = request.then(async (response) => {
    try {
      response.requestId = response.headers.get('X-Request-ID')
    } catch (_) {}
    logger.debug(`REQUEST - ${rest.method || 'GET'} ${url?.toString ? url.toString() : String(url)}`, response.status, response.requestId)

    if (response.status === 333) {
      let result, message
      try {
        if (navigate) {
          result = await response.clone().json()
          const { redirect_to: redirectTo } = result
          if (!redirectTo) throw new Error(`No Redirect Given: ${JSON.stringify(result)}`)
          if (router) {
            const url = new URL(redirectTo, window.location)
            router.push(url.pathname + url.search)
            throw new FetchError(`Server Redirected to: ${redirectTo}`, { response, result })
          } else {
            window.location.href = redirectTo
            await new Promise(_resolve => {}) // infinite promise while page goes away
          }
        }
      } catch (err) {
        logger.error(err)
        message = 'Server Failed to Redirect'
        throw new FetchError(message, { response, result })
      }
    } else if (response.status > 399) {
      let result, message
      try {
        result = await response.clone().json()
        message =
          (result.error && (typeof result.error === 'string'))
            ? result.error
            : 'Failed to Fetch'
      } catch (_) {
        message = 'Failed to Fetch'
      }

      throw new FetchError(message, { response, result })
    } else if (response.redirected && /html/.test(response.headers.get('Content-Type'))) {
      const url = new URL(response.url, window.location)
      if (url.origin !== window.location.origin) {
        throw new Error(`Invalid Redirect: ${url.toString()}`)
      }
      if (router) {
        logger.debug(router)
        router.push(url.pathname + url.search)
      } else {
        const result = response.clone().text()
        if (/<html>.+<body>/m.test(result)) {
          document.querySelector('html').innerHTML = result
          window.history.pushState({}, '', url)
          refreshCSRFTokens()
        }
      }
    }

    notify('ajax_request_complete', { recalculate: true })

    return response
  })

  promise.controller = controller
  promise.abort = async () => {
    try { controller?.abort?.() } catch (_) {}
    try { await promise } catch (_) {}
  }

  return promise
}

export function fetchHeadless (url, options) {
  options = { ...(options || {}) }
  const { layout = true, ...rest } = options
  const headers = options?.headers || {}
  if (!layout) headers['X-Kipu-No-Layout'] = true
  else headers['X-Kipu-Headless'] = true

  return customFetch(url, { navigate: true, ...rest, headers })
}
