import { defineStore } from 'pinia'
import { computed, shallowReactive, reactive } from 'vue'
import { v4 as uuid } from 'uuid'

export const locationTypes = {
  content: 'content',
  fullPage: 'fullPage',
}

export const useAppSlideoutStore = defineStore('AppSlideoutStore', () => {
  const availableDrawerLocations = reactive({})
  const registerAvailableDrawer = (location, drawerRef) => { availableDrawerLocations[location] = drawerRef }
  const drawers = shallowReactive({})
  const defaultLocation = locationTypes.fullPage
  const isDrawerOpen = (type) => computed(() => Object.values(drawers).some(drawer => drawer.isOpen && drawer.location === type))
  const registerDrawer = (upstream) => {
    const key = generateKey()
    const drawer = shallowReactive(upstream || { isOpen: false, key, location: defaultLocation })
    drawer.key = key
    drawers[key] = drawer
    return drawer
  }
  const generateKey = () => uuid()
  const removeDrawer = drawerOrKey => {
    if (drawerOrKey?.key) drawerOrKey = drawerOrKey.key
    const drawer = drawers[drawerOrKey]
    if (drawer) drawer.isOpen = false
    delete drawers[drawerOrKey]
  }

  return {
    drawers,
    availableDrawerLocations,
    registerAvailableDrawer,
    isDrawerOpen,
    registerDrawer,
    removeDrawer,
  }
})
